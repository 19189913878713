import './polyfills';
import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { store } from './store/store';
import initTgSettings from './tools/initTgSettings';
import initNoCache from './tools/noCache';
import Error from './pages/Error/Error';
//import * as Sentry from '@sentry/react';
//import reportWebVitals from './reportWebVitals';
//import { initGA } from './analytics';
//import { initSentry } from './sentry';
const App = lazy(() => import('./App'));

initTgSettings();
initNoCache();
//initGA();
//initSentry();

if (window.location.pathname !== '/') {
    window.history.replaceState({}, '', '/');
}

function Fallback() {
    return <Error />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary FallbackComponent={Fallback}>
        <Provider store={store}>
            <TonConnectUIProvider
                manifestUrl={`${process.env.REACT_APP_LINK}/tonconnect-manifest.json`}
            >
                <Suspense fallback={null}>
                    <App />
                </Suspense>
            </TonConnectUIProvider>
        </Provider>
    </ErrorBoundary>
);

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dailyRewards: [],
    dailyStreak: 0,
    isRewarded: null,
};

export const dailySlice = createSlice({
    name: 'daily',
    initialState,
    reducers: {
        setDailyRewards: (state, action) => {
            state.dailyRewards = action.payload;
        },
        setDailyRewardStreak: (state, action) => {
            state.dailyStreak = action.payload;
        },
        setIsRewarded(state, action) {
            state.isRewarded = action.payload;
        },
    },
});

export const { setDailyRewards, setDailyRewardStreak, setIsRewarded } = dailySlice.actions;

export default dailySlice.reducer;

import { setAppPrepared, setError, setSettings } from './slices/appSlice';
import { setBoosts, setProducts, setOpenResumeCollectorModal } from './slices/boostSlice';
import { setDailyRewards, setDailyRewardStreak, setIsRewarded } from './slices/dailySlice';
import { setFriends, setTotalFriends, setFriendsLinkNext, setCurrentFriendsPage, setTotalFriendsPages, setReferralTokens, setRemindedFriends } from './slices/friendSlice';
import { setGames } from './slices/gameSlice';
import { setTasks, setAchieves } from './slices/missionSlice';
import { setTonAmount, setIsPaymentLoading, setTotalTons } from './slices/paymentSlice';
import { setInitData, setUserInfo } from './slices/telegramSlice';
import { setWithdraws, setWithdrawsLinkNext, setCurrentWithdrawsPage } from './slices/withdrawSlice';

import appReducer from './slices/appSlice';
import boostReducer from './slices/boostSlice';
import dailyReducer from './slices/dailySlice';
import friendReducer from './slices/friendSlice';
import gameReducer from './slices/gameSlice';
import missionReducer from './slices/missionSlice';
import paymentReducer from './slices/paymentSlice';
import telegramReducer from './slices/telegramSlice';
import withdrawReducer from './slices/withdrawSlice';

const appAction = { setAppPrepared, setError, setSettings };
const boostAction = { setBoosts, setProducts, setOpenResumeCollectorModal };
const dailyAction = { setDailyRewards, setDailyRewardStreak, setIsRewarded };
const friendAction = {
    setFriends,
    setTotalFriends,
    setFriendsLinkNext,
    setCurrentFriendsPage,
    setTotalFriendsPages,
    setReferralTokens,
    setRemindedFriends,
};
const gameAction = { setGames };
const missionAction = { setTasks, setAchieves };
const paymentAction = { setTonAmount, setIsPaymentLoading, setTotalTons };
const telegramAction = { setUserInfo, setInitData };
const withdrawAction = {
    setWithdraws,
    setWithdrawsLinkNext,
    setCurrentWithdrawsPage,
};

const reducers = {
    appReducer,
    boostReducer,
    dailyReducer,
    friendReducer,
    gameReducer,
    missionReducer,
    paymentReducer,
    telegramReducer,
    withdrawReducer,
};

export {
    appAction,
    boostAction,
    dailyAction,
    friendAction,
    gameAction,
    missionAction,
    paymentAction,
    telegramAction,
    withdrawAction,
};

export default reducers;

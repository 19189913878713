import React from 'react';
import RoundedStar from '../../assets/animations/RoundedStar/RoundedStar.svg';
import './Background.scss';

const Background = () => {
    return (
        <div className="container">
            <div className="area">
                <ul className="stars">
                    <li>
                        <img src={RoundedStar} alt="star" />
                    </li>
                    <li>
                        <img src={RoundedStar} alt="star" />
                    </li>
                    <li>
                        <img src={RoundedStar} alt="star" />
                    </li>
                    <li>
                        <img src={RoundedStar} alt="star" />
                    </li>
                    <li>
                        <img src={RoundedStar} alt="star" />
                    </li>
                    <li>
                        <img src={RoundedStar} alt="star" />
                    </li>
                    <li>
                        <img src={RoundedStar} alt="star" />
                    </li>
                    <li>
                        <img src={RoundedStar} alt="star" />
                    </li>
                    <li>
                        <img src={RoundedStar} alt="star" />
                    </li>
                    <li>
                        <img src={RoundedStar} alt="star" />
                    </li>
                </ul>
            </div>
            <div
                className="background"
                /*style={{
                    height: smallSizePhone ? '300px' : '450px',
                    width: smallSizePhone ? '300px' : '450px',
                }}*/
            ></div>
            <div className="blur"></div>
        </div>
    );
};

export default Background;
